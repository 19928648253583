import { render, staticRenderFns } from "./sousTraitant.vue?vue&type=template&id=ddd6abfe&scoped=true"
import script from "./sousTraitant.vue?vue&type=script&lang=js"
export * from "./sousTraitant.vue?vue&type=script&lang=js"
import style0 from "./sousTraitant.vue?vue&type=style&index=0&id=ddd6abfe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd6abfe",
  null
  
)

export default component.exports